import React, { useEffect, useState, useContext } from 'react'
import { SessionContext } from "./sessions";
import Spinner from './Spinner';
import { Api } from './Api';
import Icon from './Icon';
import Modal from './Modal';



const AthleteEnrollment = ({toggleModal, enrollmentID}) => {

  const STATUSES = ["pending", "accepted", "denied"];
  const session = useContext(SessionContext);
  const [enrollment, setEnrollment] = useState(null);

  useEffect(() => {
    fetchEnrollment();
  }, []);

  const fetchEnrollment = async () => {
    const r = await Api(session, `${process.env.REACT_APP_API_URL}/enrollments/${enrollmentID}`);
    console.log(r);
    setEnrollment(r);
  }

  if (enrollment === null) {
    return <Spinner />
  }

  return(

    <form>
      <div className="modal-header flex-cont align-center">
        <div className="flex-1">
          <h2>{enrollment.athlete_user.full_name} - {enrollment.status.toUpperCase()}</h2>
          <div>{enrollment.created_at}</div>
        </div>
        <div onClick={toggleModal} className="button close">X</div>
      </div>

      <div className="padding-1">
        
        {enrollment.consult_submission && enrollment.consult_submission.consult_answers &&  enrollment.consult_submission.consult_answers.map(ans => {
          return (
            <div className="margin-bottom-2">
              <div className="bold">
                {ans.cached_question}
              </div>
              {ans.answer}
            </div>
          );
        })}
      </div>

      <div className="modal-footer align-center flex-cont">
        <div className="flex-1 align-center flex-cont">
          <input type="submit" value="Save" className="margin-right-half button" />
          <div onClick={toggleModal} className="text-link margin-right-2">Cancel</div>

          <label className="padding-right-1 fit no-margin">
            <select>
              {STATUSES.map(status => <option>{status.toUpperCase()}</option>)}
            </select>
          </label>
          <label className="no-margin">
            <textarea name="note" placeholder="Note to applicant" />
          </label>
          
        </div>
      </div>
    </form>
  )
}



const MyAthletes = () => {
  const session = useContext(SessionContext);
  const [enrollments, setEnrollments] = useState(null);
  const [currentEnrollmentID, setCurrentEnrollmentID] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => { 
    fetchEnrollments();
  }, []);


  const fetchEnrollments = async () => {
    const r = await Api(session, `${process.env.REACT_APP_API_URL}/enrollments`);
    console.log(r);
    setEnrollments(r);
  }

  
  const onEnrollmentClick = (id) => {
    setCurrentEnrollmentID(id);
    toggleModal();
  }

  const toggleModal = () => {
    setIsModalOpen(prev => !prev);
  };

  return (
    <>
      <h1>My Athletes</h1>
      {JSON.stringify(currentEnrollmentID)}
      {isModalOpen &&
        <Modal toggleModal={toggleModal}>
          <AthleteEnrollment toggleModal={toggleModal} enrollmentID={currentEnrollmentID}  />
        </Modal>
      }
      {enrollments === null &&
        <Spinner />
      }
      {enrollments && enrollments.length === 0 ? (
        <div>You currently don't have any athletes.</div>
      ) : (
        <table>
          <tbody>
            {enrollments && enrollments.map((enrollment) => {
              return (
                <tr key={enrollment.id}>
                  <td>
                    <div onClick={() => onEnrollmentClick(enrollment.id)}>
                      <Icon icon="eye" />
                    </div>
                  </td>
                  <td>
                    {enrollment.created_at}
                  </td>
                  <td>
                    {enrollment.status.toUpperCase()}
                  </td>
                  <td>
                    {enrollment.athlete_user.full_name}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      )}
    </>
  );
};

export default MyAthletes;