import React, { useContext, useEffect, useState } from 'react';
import { SessionContext } from './sessions'
import Login from './Login'
import { navigate } from "@reach/router"
import { Api } from './Api'
import Spinner from './Spinner'


const PrivateRoute = ({as: Component, ...props}) => {
  const session = useContext(SessionContext);
  const [loggedIn, setLoggedIn] = useState(false);
  const [preAuth, setPreAuth] = useState(true);

  useEffect(() => {

    let didCancel = false;
    const checkLoggedIn = async () => {
      try {
        const result = await Api(session, `${process.env.REACT_APP_API_URL}/auth/check_auth`);
        if (!didCancel) {
          setLoggedIn(true);
          setPreAuth(false);
        }
      } catch (error) {
        setLoggedIn(false);
        setPreAuth(false);
      }
    };
    checkLoggedIn();
    return () => {
      didCancel = true;
    };
  }, [])




  
  // const [loggedIn, setLoggedIn] = useState(checkLoggedIn());


  // useEffect(() => {
  //   const checkAuth = async () => {
  //     Api(session, `${process.env.REACT_APP_API_URL}/auth/check_auth`);
  //   }; 
  
  //   checkAuth();

  // }, [session]);



  return (
    <>
      {preAuth && 
        <></>
      }
      {!preAuth && loggedIn &&
        <Component {...props} />
      }
      {!preAuth && !loggedIn &&
        <Login />
      }
    </>
  )

}

export default PrivateRoute;