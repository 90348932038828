import request from 'yea';



export const Api = async (session, endpoint, { verb = 'get', params = {} } = {}) => {


  console.log(session);


  try {
    const response = await request[verb](endpoint)
    .headers({
      'Authorization': `${session.authToken}`
    })
    .json(params);
    console.log(response);
    return(response.data);

  } catch (e) {
    console.log("Error", e.response);
    if (e && e.response && e.response.status) {
      if (e.response.status === 401) {
        session.logoutUser();
      }
    }

    return(e);
  }

};
