import React, { useContext } from 'react';
import { SessionContext } from './sessions'
import Profile from './Profile'
import { navigate } from "@reach/router"


const LoggedoutRoute = ({as: Component, ...props}) => {
  const session = useContext(SessionContext);


  const loggedIn = () => {
    return session && session.authToken && session.userID
  }

  if ( loggedIn() ) {
    navigate('/profile');
    return <Profile />
  } else {
    return <Component {...props} />
  }

}

export default LoggedoutRoute;