import React, { useEffect, useState, useContext } from 'react'
import { SessionContext } from "./sessions";


import { Api } from './Api';

const MyCoaches = () => {
  const session = useContext(SessionContext);
  const [enrollments, setEnrollments] = useState(null);

  useEffect(() => { 
    fetchEnrollments();
  }, []);

  // const [{ status, response }, makeRequest] = useApiRequest(
  //   `${process.env.REACT_APP_API_URL}/enrollments`,
  //   {
  //       verb: 'get',
  //   }
  // );

  const fetchEnrollments = async () => {
    const r = await Api(session, `${process.env.REACT_APP_API_URL}/enrollments`);
    setEnrollments(r);
  }


  return (
    <>
      <h1>My Coaches</h1>

      {!enrollments ? (
        <div>No enrollments.</div>
      ) : (
        <table>
          {enrollments && enrollments.map((enrollment) => {
            return (
              <tr>
                <td>
                  {enrollment.created_at}
                  {JSON.stringify(enrollment)}
                </td>
                <td>
                  {enrollment.status.toUpperCase()}
                </td>
                <td>
                  {enrollment.athlete_user.full_name}
                </td>
              </tr>
            )
          })}
        </table>
      )}
    </>
  );
};

export default MyCoaches;