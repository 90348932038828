import React, { useContext, useState, useEffect } from 'react';
import { SessionContext } from './sessions';
import { Api } from './Api';
import Modal from './Modal';
import useForm from 'react-hook-form';
import './App.scss'
import Icon from './Icon'
import * as yup from "yup";
import Spinner from './Spinner'

const ConsultSchema = yup.object().shape({
  name: yup.string().required("Your questionaire must have a title"),
});

const ConsultForm = ({toggleModal, setConsults, currentConsult}) => {
  // const formConsult  = currentConsult || {};



  const setDefault = () => {
    if (currentConsult && currentConsult.questions) {
      const i = currentConsult.questions.map((q, index) => index);
      return i;
    }
    return [];
  }

  
  const session = useContext(SessionContext);
  const [formConsult, setFormConsult] = useState(currentConsult || {});
  const [indexes, setIndexes] = useState(setDefault());
  const [removedIndexes, setRemovedIndexes] = useState([]);
  const [counter, setCounter] = useState(setDefault().length);
  const { register, errors, handleSubmit, setValue } = useForm({
    validationSchema: ConsultSchema
  });

  const onSubmit = async data => {
    console.log(data);
    const r = await Api(session, `${process.env.REACT_APP_API_URL}/consults/`, 
    {
      verb: 'post', 
      params: data
    });
    console.log(r);
    setConsults(r);
    toggleModal();  
  };

  const onEditSubmit = async data => {
    console.log(data);
    const r = await Api(session, `${process.env.REACT_APP_API_URL}/consults/${currentConsult.id}`, 
    {
      verb: 'put', 
      params: data
    });
    console.log(r);
    setConsults(r);
    toggleModal();  
  };

  const onDelete = async (consult) => {
    console.log(consult);
    const r = await Api(session, `${process.env.REACT_APP_API_URL}/consults/${consult.id}`, 
    {
      verb: 'delete'
    });
    setConsults(r);
    toggleModal();
  };

  const addQuestion = () => {
    setIndexes(prevIndexes => [...prevIndexes, counter]);
    setCounter(prevCounter => prevCounter + 1);
  };
  const removeQuestion = (index) => {
    const fieldName = `consult_questions_attributes[${index}]`;
    setIndexes(prevIndexes => [...prevIndexes.filter(item => item !== index)]);
    setValue(`${fieldName}[_destroy]`, true);
    setRemovedIndexes(prevRemovedIndexes => [...prevRemovedIndexes, index]);
  };

  


  return(
    <>
      
      
        <form onSubmit={formConsult.id ? handleSubmit(onEditSubmit) : handleSubmit(onSubmit)}>
          <div className="modal-header flex-cont align-center">
            {formConsult.id ? (<h2 className="flex-1">Edit Questionaire</h2>) : (<h2 className="flex-1">New Questionaire</h2>)}
            <div onClick={toggleModal} className="button close">X</div>
          </div>

          <div className="padding-1">
            <div className="flex-cont align-center">
              <label className="fit margin-right-1" htmlFor={`active`}>
                <input 
                  type="checkbox"
                  name={`active`} 
                  ref={register}
                  defaultChecked={formConsult.active === true ? "checked" : null}
                />
                Active
              </label>
              <label className="flex-1" htmlFor={`name`}>
                <input 
                  name={`name`} 
                  type="text" 
                  className="full" 
                  placeholder="Questionaire title"
                  ref={register}
                  defaultValue={formConsult.name}
                />
                {errors.name && <div className="field-error">{errors.name.message}</div>}
              </label>
              

            </div>
            
            <br />
            <br />

            {indexes.map((index, i) => {
              const fieldName = `consult_questions_attributes[${index}]`;
              const q = (formConsult.questions && formConsult.questions[index]) ? formConsult.questions[index] : {}
              const hide = removedIndexes.includes(index);

              return (
                <fieldset className={hide ? 'hidden' : ''} name={fieldName} key={fieldName}>
                  <input 
                    name={`${fieldName}[id]`} 
                    type="hidden" 
                    ref={register}
                    defaultValue={q.id}
                  />
                  <input 
                    name={`${fieldName}[_destroy]`} 
                    type="hidden" 
                    ref={register}
                  />

                  <div className="flex-cont">
                    <strong className="flex-1">{`QUESTION #${i+1}`}</strong>
                    <div className="text-link" onClick={() => removeQuestion(index)}>REMOVE</div>
                  </div>
                  <label htmlFor={`${fieldName}[question]`}>
                    <textarea 
                      name={`${fieldName}[question]`} 
                      className="full" 
                      ref={register}
                      defaultValue={q.question}
                    />
                  </label>
                  <label htmlFor={`${fieldName}[required]`}>
                    <input 
                      type="checkbox"
                      name={`${fieldName}[required]`} 
                      ref={register}
                      defaultChecked={q.required === true ? "checked" : null}
                    />
                    Required
                  </label>
                </fieldset>
              );
            })}

            <div className="text-link" onClick={addQuestion}>
              +
              ADD QUESTION
            </div>
            <br />
            <br />
            <br />
            <br />
            
          </div>
          <div className="modal-footer align-center flex-cont">
            <div className="flex-1 align-center flex-cont">
              <input type="submit" value="Save" className="margin-right-half button" />
              <div onClick={toggleModal} className="text-link">Cancel</div>
            </div>
            {formConsult &&
              <div onClick={() => onDelete(formConsult)} className="margin-right-half red button">Delete</div>
            }
          </div>
          
        </form>





    </>
  )
  

  // return (
  //   <>
  //     <h1>New Questionaire</h1>

  //     <form onSubmit={handleSubmit(onSubmit)}>
  //       <label htmlFor={`name`}>
  //         <input 
  //           name={`name`} 
  //           type="text" 
  //           className="full" 
  //           placeholder="Questionaire title"
  //           ref={register}
  //         />
  //       </label>
  //       <br />
  //       <br />

  //       {indexes.map(index => {
  //         const fieldName = `consult_questions_attributes[${index}]`;
  //         return (
  //           <fieldset name={fieldName} key={fieldName}>
  //             <strong>{`QUESTION #${index+1}`}</strong>
  //             <label htmlFor={`${fieldName}[question]`}>
  //               <textarea 
  //                 name={`${fieldName}[question]`} 
  //                 className="full" 
  //                 ref={register}
  //               />
  //             </label>
  //             <label htmlFor={`${fieldName}[required]`}>
  //               <input 
  //                 type="checkbox"
  //                 name={`${fieldName}[required]`} 
  //                 ref={register}
  //               />
  //               Required
  //             </label>
  //           </fieldset>
  //         );
  //       })}

  //       <div class="button" onClick={addQuestion}>Add Question</div>
  //       <br />
  //       <br />
  //       <br />
  //       <br />
  //       <input type="submit" value="Save" className="button" />
  //     </form>

  //   </>
  // )
}




const Consults = () => {

  const session = useContext(SessionContext);
  const [consults, setConsults] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentConsult, setCurrentConsult] = useState(null);

  useEffect(() => {
    fetchConsults();
  }, []);

  const fetchConsults = async () => {
    const response = await Api(session, `${process.env.REACT_APP_API_URL}/consults`);
    console.log(response);
    setConsults(response);
  };

  const toggleModal = () => {
    setIsModalOpen(prev => !prev);
  };


  const handleNewClick = () => {
    setCurrentConsult(null);
    toggleModal();
  }
  const handleEditClick = (consult) => {
    setCurrentConsult(consult);
    toggleModal();
  }



  
  return(
    <>
      <h1>Consults</h1>
      

      <div className="button" onClick={handleNewClick}>New Questionaire</div>

      {isModalOpen &&
        <Modal toggleModal={toggleModal}>
          <ConsultForm toggleModal={toggleModal} setConsults={setConsults} currentConsult={currentConsult}  />
        </Modal>
      }

      {consults === null &&
        <Spinner />
      }

      {consults && consults.length === 0 &&
        <em>No consults found.</em>
      }

      {consults && consults.map((consult) => {
        return(
          <div className="card">
            {consult.active === true && <div>ACTIVE</div>}
            <div className="flex-cont">
              <strong className="flex-1">{consult.name}</strong>
              <div onClick={() => handleEditClick(consult)}>
                <Icon icon="ellipsis-v" />
              </div>
            </div>
            <div>
              {consult.questions && consult.questions.map(q => {
                return(
                  <div key={q.id} className="flex-cont">
                    {q.required === true && <div>*</div>}
                    <div>{q.question}</div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </>
  )
}

export default Consults;