import React, { useEffect, useState, useContext } from 'react';
import { SessionContext } from './sessions'
import { Api } from './Api';
import Modal from './Modal';
import Icon from './Icon'
import * as yup from "yup";
import useForm from 'react-hook-form';

const AnswerSchema = yup.object().shape({
  answer: yup.string().required("Answer is requried."),
  
});

const ConsultSchema = yup.object().shape({
  consult_answers_attributes: yup.array().of(AnswerSchema)
});


const ApplyForm = ({toggleModal, coachID, setCoach}) => {
  const session = useContext(SessionContext);
  const [consult, setConsult] = useState(null);
  const { register, errors, handleSubmit, setValue } = useForm({
    validationSchema: ConsultSchema
  });

  useEffect(() => {
    fetchActiveConsult();
  }, []);

  const fetchActiveConsult = async () => {
    const r = await Api(session, `${process.env.REACT_APP_API_URL}/users/${coachID}/get_active_consult`);

    setConsult(r)
  }

  const onSubmit = async data => {
    const r = await Api(session, `${process.env.REACT_APP_API_URL}/enrollments/`, {
      verb: 'post', 
      params: {
        coach_user_id: coachID,
        consult_submission_attributes: {
          ...data,
          consult_id: consult.id,
        }
      }
    });

    toggleModal();
    setCoach(r);

    console.log(r);
  };

  console.log(errors);

  return( 
    <>

        <form onSubmit={handleSubmit(onSubmit)}> 

          <div className='modal-header flex-cont align-center'>
            <h1 className="flex-1">Application</h1>
            <div onClick={toggleModal} className="button close">X</div>
          </div>

          <div className="padding-1">
            {consult && consult.questions && consult.questions.map((question, index) => {
              const fieldName = `consult_answers_attributes[${index}]`;
              return(
                <div className="margin-bottom-1">
                  <div className="font-1-5">#{index + 1} {question.question}</div>
                  <input ref={register} type="hidden" name={`${fieldName}[consult_question_id]`} value={question.id} />
                  <textarea
                    name={`${fieldName}[answer]`}
                    placeholder="Your answer"
                    ref={register}
                   />
                   {errors[`${fieldName}.answer`] && <div className="field-error">{errors[`${fieldName}.answer`].message}</div>}
                </div>
              );
            })}
            
          </div>

          <div className='modal-footer flex-cont align-center'>
            <input type="submit" value="Submit" className="margin-right-half button" />
            <div onClick={toggleModal} className="text-link">Cancel</div>
          </div>
        </form>      
    </>
  )
};

const CoachProfile = ({id}) => {
  const session = useContext(SessionContext);
  const [coach, setCoach] = useState(null);
  const [isApplyModalOpen, setIsApplyModalOpen] = useState(false);

  useEffect(() => {
    fetchCoach();
  }, [id]);

  const fetchCoach = async () => {
    const response = await Api(session, `${process.env.REACT_APP_API_URL}/users/${id}/coach_profile`);
    console.log(response);
    setCoach(response);
  };

 
  const tryEnrollment = async () => {
    if (enrollDisabled()) {
      return
    }
    const response = await Api(session, `${process.env.REACT_APP_API_URL}/users/${id}/create_enrollment`, {
      verb: 'post'
    });
    console.log(response);
    setCoach(response);
  };

  const enrollButonText = () => {
    const enrollment = coach.enrollment;

    if (enrollment.status === 'blank')
      return "Apply"
    else if (enrollment.status === 'pending')
      return "Application Pending"
    else if (enrollment.status === 'acccepted')
      return "Application Accepted"
    else
      return "TODO"
  }

  const enrollDisabled = () => {
    if (!coach || !coach.enrollment || coach.enrollment.status !== 'blank')
      return true

    return false
  }

  const toggleApplyModal = () => {
    setIsApplyModalOpen(prev => !prev);
  };


  if (!coach) {
    return(
      <div>LOADING...</div>
    );
  }

  return (
    <div className="narrow">
      <h1>{`${coach.user.first_name} ${coach.user.last_name}`}</h1>

      <div>{JSON.stringify(coach)}</div>

      {isApplyModalOpen &&
        <Modal toggleModal={toggleApplyModal}>
          <ApplyForm setCoach={setCoach} toggleModal={toggleApplyModal} coachID={id}  />
        </Modal>
      }

      
      <div className="narrow">
      <div className="flex-cont align-center">
        <div className="flex-1 flex-cont align-center">
          <div className="avatar-cont">
          </div>
          <div className="margin-left-1">
            {coach.first_name} {coach.last_name}
            <div>
              {coach.user.bio}
            </div>
          </div>
        </div>

 
        <>
          <div>
            {!coach.enrollment.has_consult ? (
                <div>No consult found.</div>
              ) : (
                <div className={`button ${enrollDisabled() ? 'disabled' : ''}`} onClick={toggleApplyModal}>{enrollButonText()}</div>
              )
            }
          </div>
        </>
        

      </div>
      <div className="">
        <div>
          {coach.first_name} {coach.last_name}
        </div>
        <div>
          {coach.bio}
        </div>
      </div>
    </div>
    </div>
  )
};

export default CoachProfile;


