import React, { useContext } from 'react';
import logo from './logo.svg';
import './App.scss';
import { Router, Link } from "@reach/router";
import Join from './Join';
import Login from './Login';
import Profile from './Profile';
import Coaches from './Coaches';
import CoachProfile from './CoachProfile'
import Home from './Home';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheckSquare, faCoffee, faEllipsisV, faEye } from '@fortawesome/free-solid-svg-icons'
import PrivateRoute from './PrivateRoute'
import LoggedoutRoute from './LoggedoutRoute'
import Spinner from './Spinner'


import { SessionContext, SessionContextProvider } from "./sessions";


library.add(faCheckSquare, faCoffee, faEllipsisV, faEye)


const Routes = () => {
  const state = useContext(SessionContext);

  function RouterComponent({ children }) {
    return <>{children}</>;
  }

  return (
      <div className="App">
        <nav className="main narrow">
          <div className="logo-cont">
            <Link to="/">
              <img src={logo} alt="Nomgains logo" />
              
            </Link>
          </div>
          <div className="nav-links-cont">
            {state.userID ? (
              <>
                <Link className="text-link" to="/coaches">Coaches</Link> 
                <Link className="text-link" to="/profile">My Profile</Link> 
              </>
            ) : (
              <>
                <Link to="/login" className="text-link">Login</Link>
                <Link to="/join" className="button">Join Free</Link>
              </>
            )
            }
            

          </div>
        </nav>

        <main>
          <Router primary={false} component={RouterComponent}>
            {/* <Profile  path="profile/*" /> */}
            <PrivateRoute as={Profile} path="profile/*" />
            <Coaches path="/coaches" />
            <CoachProfile path="/coach/:id" />
            <LoggedoutRoute as={Join} path="/join" />
            <LoggedoutRoute as={Login} path="/login" />
            <Home path="/" />
          </Router>
        </main>

        <footer>
          <pre style={{width: '90vw', wordWrap: "break-word"}}>
            {JSON.stringify(state)}
          </pre>
        </footer>
      </div>
  );
};






function App() {
  return (
    <div>
      <SessionContextProvider>
        <Routes />
      </SessionContextProvider>
    </div>
  );
}

export default App;
