import React from 'react';
import { Link } from "@reach/router";
import Spinner from './Spinner';

const Home = props => {
  return (
    <main>
      <div className="home-banner center narrow">
        <h1 className="font-5">
          Find your coach.
        </h1>
        <Link to='/join' className="font-2 button">Get Started</Link>
        
        <br />
        <br />
        <div className="font-2">

        </div>
      </div>
    </main>
  );
};

export default Home;    