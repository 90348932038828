import React, { useState, useContext } from 'react';
import Modal from './Modal';
import request from 'yea';
import { SessionContext } from "./sessions";
import './Profile.scss'; 
import { Router, Link } from "@reach/router";
import MyAthletes from './MyAthletes';
import MyCoaches from './MyCoaches';
import ProfileHome from './ProfileHome';
import Consults from './Consults';
import { Api } from './Api';
import { navigate } from "@reach/router"

const Profile = () => {



  const { setSession, session, authToken, logoutUser } = useContext(SessionContext);

  // const [{ status, response }, makeRequest] = useApiRequest(`${process.env.REACT_APP_API_URL}/users/${currentUser.id}/create_enrollment`, { verb: 'get' });



  // const tryEnrollment = async () => {
  //   makeRequest();
  // };

  // if (!currentUser) {
  //   return(
  //     <div>LOADING...</div>
  //   );
  // }
  // console.log(currentUser);

  const tryLogout = async () => {
    logoutUser();
  }

  return (
    <div className="parent">
      <div className="content-cont flex-cont">
        
        <div className="side-nav">
          <ul>
            <li><Link to="./">Profile</Link></li>
            <li><Link to="my-athletes">My Athletes</Link></li>
            <li><Link to="my-coaches">My Coaches</Link></li>
            <li><Link to="consults">Consults</Link></li>
          </ul>

          <br />
          <div className="text-link" onClick={tryLogout}>Logout</div>
        </div>

        <div className="content">


          <Router>
            <ProfileHome  path="/" />
            <MyAthletes path="my-athletes" />
            <MyCoaches path="my-coaches" />
            <Consults path="consults" />
          </Router>


          
        </div>
      </div>
    </div>
    
  );
};




export default Profile;