import React, { useContext } from 'react';
import { SessionContext } from "./sessions";
import { navigate } from "@reach/router"
import { Api} from './Api';
import * as yup from "yup";
import useForm from 'react-hook-form';


const LoginSchema = yup.object().shape({
  email: yup.string().required("Email is required."),
  password: yup.string().required("Password is required."),
});

const Login = props => {

  const session = useContext(SessionContext);
  const { register, errors, handleSubmit } = useForm({
    validationSchema: LoginSchema
  });

  const tryLogin = async data => {
    
    const response = await Api(session, `${process.env.REACT_APP_API_URL}/auth/login`, {
      verb: 'post',
      params: data
    })

    session.loginUser(response);

    console.log(response);
    navigate('/profile');
  }

  
  return(
    <div className="narrow">
      <h1>Login</h1>
      <div>{JSON.stringify(session)}</div>

      <div className="card">
        <form onSubmit={handleSubmit(tryLogin)}>
          <label htmlFor="email"> 
            <input 
              type="text" 
              ref={register}
              placeholder="Email"
              name="email"
            />
            {errors.email && <div className="field-error">{errors.email.message}</div>}
          </label>
          <label htmlFor="pw"> 
            <input 
              type="password" 
              ref={register}
              placeholder="Password"
              name="password"
            />
            {errors.password && <div className="field-error">{errors.password.message}</div>}
          </label>


          <input type="submit" value="Submit" />
        </form>
      </div>
    </div>
    
  )
};

export default Login;