import React, { useState, useEffect, useContext } from 'react'
import { Link } from "@reach/router";
import request from 'yea';
import { SessionContext } from './sessions'
import { Api } from './Api';

const Coaches = () => {
  const [coaches, setCoaches] = useState(null);
  const session = useContext(SessionContext);

  useEffect(() => {
    fetchCoaches();
  }, []);

  const fetchCoaches = async () => {
    const response = await Api(session, `${process.env.REACT_APP_API_URL}/users/coaches`);
    console.log(response);
    setCoaches(response);
  };

  const loading = coaches === null;
  

  return(
    <div className="narrow">
      <h1>Coaches</h1>

      {loading &&
        <div>LOADING...</div>
      }
      {!loading &&

        <div>
          {coaches.length === 0 ? (
            <div>No coaches found.</div>
          ) : (
            <table>
              <tbody>
                {coaches && coaches.map((coach) => {
                  return(
                    <tr key={coach.id}>
                      <td>
                        <Link state={{ user: coach }}to={`/coach/${coach.id}`}>  {coach.first_name} {coach.last_name}</Link>
                        
                        </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          )
          }
        </div>

      }

    </div>
    
  )
}

export default Coaches;