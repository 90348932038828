import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Icon = ({icon}) => {

  return (
    <div className="icon-cont">
      <FontAwesomeIcon icon={icon} />
    </div>
    
  )
}
export default Icon