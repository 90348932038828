import React from 'react';
import './Modal.scss';

const Modal = ({toggleModal, children}) => {
  return(
    <>
      <div onClick={toggleModal} className="modal-bg">
      </div>
      <div className="modal-cont">
        <div className="modal-content">
          <div className="modal-inner-content">
            
            {children}
          </div>
        </div>
      </div>
    </>
  )
}

export default Modal;